<template>
  <div>
    <div class="input-group">
      <div class="flex justify-between slider items-center mb-2 mt-8">
        <div class="mr-4 text-gray-600">Wet</div>
        <div class=" ml-4 font-semibold">
          {{ food.wetValue }}
          <span class=" font-normal text-gray-600">{{
            food.wetValue === 1 ? "Pouch" : " Pouches"
          }}</span>
        </div>
      </div>

      <div class="flex items-center">
        <range-slider
          class="slider w-full"
          :class="'slider-' + color"
          min="0"
          max="3"
          step="1"
          v-model="food.wetValue"
          @change="updateValues('wet', $event)"
        >
          <template v-slot:knob
            ><slider-knob class="animated shake" :color="color"
          /></template>
        </range-slider>
      </div>
    </div>

    <div class="mb-6 " :class="'slider-' + color">
      <label>Do you eat dry food?</label>
      <div class="flex items-center rounded mt-4 ">
        <label
          for="dry-yes"
          class="flex items-center  w-1/2 p-3 px-4 m-0 rounded border-2 border-gray-300 mr-2"
          :class="{ 'selected-dry': food.dryValue == 1200 }"
        >
          <input
            type="radio"
            @change="updateValues('dry', $event)"
            id="dry-yes"
            value="1200"
            class="mr-2"
            v-model="food.dryValue"
          />Yes
        </label>

        <label
          for="dry-no"
          class="flex items-center m-0 flex items-center  w-1/2 p-3 px-4 m-0 rounded border-2 border-gray-300  ml-2"
          :class="{ 'selected-dry': food.dryValue == 0 }"
          ><input
            type="radio"
            @change="updateValues('dry', $event)"
            id="dry-no"
            value="0"
            class="mr-2"
            v-model="food.dryValue"
          />No
        </label>
      </div>
    </div>
    <!-- <div class="input-group">
      <div class="flex justify-between slider items-center mb-2">
        <div class="mr-4 text-gray-600">Dry</div>
        <div class=" ml-4 font-semibold">
          {{ dryValueKg }} <span class=" font-normal text-gray-600">Kg</span>
        </div>
      </div>
      <div class="flex items-center mb-8">
        <range-slider
          class="slider"
          :class="'slider-' + color"
          min="0"
          max="3200"
          step="400"
          v-model="food.dryValue"
          @change="updateValues('dry', $event)"
        >
          <template v-slot:knob
            ><slider-knob
              :color="color"
              :class="tippyClass"
              data-tippy-content="Minimum quantity for dry food is <span class='font-semibold text-black'>0.8kg</span>"
          /></template>
        </range-slider>
      </div>
    </div> -->
  </div>
</template>

<script>
import RangeSlider from "vue-range-slider";
import SliderKnob from "@/components/pricing/SliderKnob";
import tippy from "tippy.js";
export default {
  props: ["wetFood", "dryFood", "color", "id", "numCats", "isActive"],
  data() {
    return {
      food: {
        wetValue: this.wetFood,
        dryValue: this.dryFood
      },
      dryNotice: false,
      dryTippy: null,
      tippyClass: "tippy-dry-" + this.id,
      dryMinValue: this.numCats == 1 ? 2000 : 1200
    };
  },
  computed: {
    dryValueKg: function() {
      return this.food.dryValue / 1000;
    },
    minValue: function() {
      if (this.numCats) {
        if (this.numCats == 1) {
          return 2000;
        } else {
          return 1200;
        }
      } else {
        return 2000;
      }
    }
  },

  components: {
    RangeSlider,
    SliderKnob
  },
  watch: {
    minValue: function() {
      this.updateValues("dry", this.food.dryValue);
      this.updateValues("wet", this.food.wetValue);
    }
    // dryMinValue: function(value) {
    //   // let formattedValue = value / 1000 + "kg";
    //   // this.dryTippy[0].setContent(
    //   //   `Minimum quantity for dry food is <span class='font-semibold text-black'>${formattedValue}</span>`
    //   // );
    // }
  },
  mounted() {
    this.checkMinimums();
    this.dryTippy = tippy(".tippy-dry-" + this.id, {
      allowHTML: true,
      theme: "warning",
      trigger: "manual",
      animation: "shift-away",
      maxWidth: 160
    });
    this.updateValues("dry", this.dryFood);
    this.updateValues("wet", this.wetFood);
    // let formattedValue = this.dryMinValue / 1000 + "kg";
    // this.dryTippy[0].setContent(
    //   `Minimum quantity for dry food is <span class='font-semibold text-black'>${formattedValue}</span>`
    // );
  },
  methods: {
    updateValues: function(type, event) {
      if (type === "wet" && this.isActive) {
        this.food.wetFood = event * 30;
      }
      if (type === "dry" && this.isActive) {
        console.log(event);
        if (event) {
          this.food.dryFood = 1200;
        } else {
          this.food.dryFood = 0;
        }
      }
      // if (type === "dry" && this.isActive) {
      //   if (event < this.minValue && this.food.wetValue === 0) {
      //     this.food.dryValue = this.minValue;
      //     this.dryTippy[0].show();
      //   }
      //   if (event === 400) {
      //     if (event < this.minValue && this.food.wetValue === 0) {
      //       this.food.dryValue = this.minValue;
      //       this.dryTippy[0].show();
      //     } else {
      //       this.food.dryValue = 800;
      //       this.dryTippy[0].show();
      //     }
      //   }
      // }
      // if (type === "wet" && this.isActive) {
      //   if (this.food.wetValue === 0) {
      //     this.dryMinValue = this.minValue;
      //     if (this.food.dryValue < this.minValue) {
      //       this.dryTippy[0].show();
      //       this.food.dryValue = this.minValue;
      //     }
      //   } else {
      //     this.dryMinValue = 800;
      //   }
      // }
      this.food.dryValue = parseInt(this.food.dryValue);
      this.food.wetValue = parseInt(this.food.wetValue);
      this.$parent.$emit(`has-values-${this.id}`, this.food);
    },
    checkMinimums: function() {
      if (this.food.dryValue < this.minValue && this.food.wetValue === 0) {
        this.food.dryValue = this.minValue;
      }
    }
  }
};
</script>

<style lang="scss">
$slider-height: 20px;
$slider-width: 130px;
$rail-height: 10px;
$knob-size: 30px;
$knob-color: none;

$knob-border: none;
$knob-shadow: none;
// import the built-in vue-range-slider style
@import "~vue-range-slider/dist/vue-range-slider.scss";

input[type="text"] {
  &.range-slider-hidden {
    display: none;
  }
}

.range-slider-rail {
  height: 10px;
  @apply bg-gray-200;
}

.slider-teal {
  .range-slider-fill {
    @apply bg-teal-200;
  }
  .selected-dry {
    @apply border-2 border-teal-400;
  }
}
.slider-blue {
  .range-slider-fill {
    @apply bg-blue-200;
  }
  .selected-dry {
    @apply border-2 border-blue-400;
  }
}
.slider-pink {
  .range-slider-fill {
    @apply bg-pink-200;
  }
  .selected-dry {
    @apply border-2 border-pink-400;
  }
}

.range-slider-knob {
  width: 30px;
  height: 30px;
}

.slider {
  /* overwrite slider styles */
  width: 100%;
  min-width: 100%;
}
.range-slider {
  padding: 0;
}
</style>
