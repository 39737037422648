<template>
  <div class="px-4 pb-8">
    <transition
      enter-active-class="miniFadeInUp"
      leave-active-class="miniFadeOutDown"
      mode="out-in"
    >
      <div v-if="step.sliders" key="slider" class="flex">
        <div class="mx-auto">
          <pricing-slider
            :dry-food="food.dryfood"
            :wet-food="food.wetfood"
            @has-values="updateValues"
          />
          <form @submit.prevent="getPrice" class="mt-4 text-center">
            <loading-button
              :is-loading="isLoading"
              :is-enabled="true"
              class="inline-block"
            >
              <div>Get price</div>
            </loading-button>
          </form>
        </div>
      </div>
      <div v-else key="price">
        <div class="flex-grow text-center">
          <div>
            <div
              class="list-reset m-0 mb-2 mt-0 rounded border-2 border-purple-300 pt-2 text-left text-sm sm:m-8"
            >
              <div
                class="mx-6 border-b-2 py-4 text-gray-700"
                v-if="newPlan.pouches != 0"
              >
                <span class="font-semibold text-black">{{
                  newPlan.pouches
                }}</span>
                pouches of high meat content quality wet food ({{
                  newPlan.pouches / 30
                }}
                per day)
              </div>
              <div
                class="mx-6 border-b-2 py-4 text-gray-700"
                v-if="newPlan.dryfood != 0"
              >
                <span class="font-semibold text-black"
                  >{{ newPlan.dryfood / 1000 }}kg</span
                >
                of high quality, freshly prepared dry food
              </div>
              <div class="mx-6 mb-2 py-4 text-gray-700">
                <span class="font-semibold text-black">{{
                  newPlan.shelter
                }}</span>
                pouches to a fellow cat in a shelter every month
              </div>

              <flavour
                :plan="newPlan"
                :dryfood="currentPlan.dryfoodflavour"
                :wetfood="currentPlan.wetfoodflavour"
                @has-flavour="setFlavour"
                class="p-4 sm:p-0"
              />

              <div class="bg-purple-100 p-6">
                <div class="text-center text-lg">
                  <span class="block text-5xl font-semibold"
                    >{{ newPlan.displayCost | currency('£') }}
                  </span>
                  <span class="text-sm">per month (incl. delivery)</span>
                </div>
                <div class="pt-8">
                  <button
                    @click="updatePlan(currentPlan.id)"
                    class="cta-plan inline-block w-full rounded border-2 border-yellow-400 bg-yellow-200 p-3 text-base font-bold tracking-wide no-underline"
                  >
                    Update plan
                  </button>
                </div>
              </div>
            </div>
            <div
              class="mt-6 text-sm text-gray-500 underline"
              @click="goTo('sliders')"
            >
              adjust quantities
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import _ from 'lodash';
import http from '@/http';
import LoadingButton from '@/components/LoadingButton';
import PricingSlider from '@/components/pricing/PricingSlider';
import Flavour from '@/components/Flavour';
export default {
  props: ['data'],
  components: {
    PricingSlider,
    LoadingButton,
    Flavour
  },
  data() {
    return {
      currentPlan: _.clone(this.data),
      food: {
        dryfood: this.data.dryfood,
        wetfood: this.data.wetfood
      },
      flavour: {
        dryFoodFlavour: this.data.dryfoodflavour,
        wetFoodFlavour: this.data.wetfoodflavour
      },
      isLoading: false,
      newPlan: _.clone(this.data),
      step: {
        sliders: true,
        price: false
      }
    };
  },
  methods: {
    closeModal: function () {
      this.$parent.$emit('make-close');
    },
    getPrice: function () {
      this.isLoading = true;
      // this.$ma.trackEvent({
      //   category: "Click",
      //   action: "Adjust Plan - Get Price",
      //   properties: {
      //     Pouches: this.food.wetfood + " Pouches",
      //     "Dry Food": this.food.dryfood / 1000 + " Kg"
      //   }
      // });
      http
        .post('/pricing', this.food)
        .then((response) => {
          this.isLoading = false;
          this.goTo('price');

          let recommended = response.data.content[0].recommended;
          this.newPlan = response.data.content[0].options[recommended];

          // this.$ma.trackEvent({
          //   category: 'Click',
          //   action: 'Adjust Plan - Pricing plan',
          //   properties: {
          //     Pouches: this.newPlan.pouches + ' Pouches',
          //     'Dry Food': this.newPlan.dryfood / 1000 + ' Kg',
          //     Price: '£' + this.newPlan.displayCost
          //   }
          // });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    goTo: function (view) {
      if (view === 'sliders') {
        this.step.sliders = true;
        this.step.price = false;
      } else {
        this.step.sliders = false;
        this.step.price = true;
      }
    },
    updatePlan: function (id) {
      const self = this;
      // this.$ma.trackEvent({
      //   category: 'Click',
      //   action: 'Adjust Plan - Subscription Update'
      // });
      http
        .put(`/me/purchases/${id}/update`, { session: this.newPlan.session })
        .then((response) => {
          this.$store.commit('update_subscription', response);
          // this.$ma.trackEvent({
          //   category: 'Click',
          //   action: 'Adjust Plan - Update Success',
          //   properties: {
          //     Pouches: this.newPlan.pouches + ' Pouches',
          //     'Dry Food': this.newPlan.dryfood / 1000 + ' Kg',
          //     Price: '£' + this.newPlan.displayCost,
          //     'Pouches Flavour': this.flavour.wetFoodFlavour,
          //     'Dry Food Flavour': this.flavour.dryFoodFlavour
          //   }
          // });
          http
            .put(`/me/purchases/${id}/update/details`, self.flavour)
            .then(() => {
              self.closeModal();
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateValues: function (val) {
      this.food.dryfood = val.dryValue;
      this.food.wetfood = val.wetValue;
    },
    setFlavour: function (val) {
      this.flavour.dryFoodFlavour = val.dryFood;
      this.flavour.wetFoodFlavour = val.wetFood;
    }
  }
};
</script>

<style scoped></style>
