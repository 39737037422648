var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-purple-100 pb-2"},[_c('div',{staticClass:"text-md font-merri text-center mb-4 pt-4"},[_vm._v(" Choose your flavours: ")]),_c('div',{staticClass:"flex flex-col lg:flex-row"},[(_vm.selectedPlan.pouches != 0)?_c('div',{staticClass:"w-full sm:w-2/3 md:w-1/2 text-sm text-left lg:p-4 pt-0 bg-purple-100 rounded mx-auto pb-4 border-purple-300"},[(_vm.selectedPlan.dryfood != 0)?_c('div',{staticClass:"mb-3 text-center font-medium text-xs uppercase"},[_vm._v(" wet pouches ")]):_vm._e(),_c('div',{staticClass:"flex items-center p-2 px-3 rounded justify-between",class:{
          'bg-white shadow-lg  pulse default-transition':
            _vm.flavour.wetFood === 'Chicken'
        }},[_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.flavour.wetFood),expression:"flavour.wetFood"}],attrs:{"id":"wetFoodChicken","type":"radio","value":"Chicken"},domProps:{"checked":_vm._q(_vm.flavour.wetFood,"Chicken")},on:{"change":function($event){return _vm.$set(_vm.flavour, "wetFood", "Chicken")}}}),_vm._m(0)]),_c('div',[(_vm.flavour.wetFood === 'Chicken')?_c('font-awesome-icon',{staticClass:"text-purple-500 animated bounceIn default-transition",attrs:{"icon":['far', 'check']}}):_vm._e()],1)]),_c('div',{staticClass:"flex items-center p-2 px-3 rounded justify-between",class:{
          'bg-white shadow-lg  pulse default-transition':
            _vm.flavour.wetFood === 'Tuna'
        }},[_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.flavour.wetFood),expression:"flavour.wetFood"}],attrs:{"id":"wetFoodTuna","type":"radio","value":"Tuna"},domProps:{"checked":_vm._q(_vm.flavour.wetFood,"Tuna")},on:{"change":function($event){return _vm.$set(_vm.flavour, "wetFood", "Tuna")}}}),_vm._m(1)]),_c('div',[(_vm.flavour.wetFood === 'Tuna')?_c('font-awesome-icon',{staticClass:"text-purple-500 animated bounceIn default-transition",attrs:{"icon":['far', 'check']}}):_vm._e()],1)]),_c('div',{staticClass:"flex items-center p-2 px-3 rounded justify-between",class:{
          'bg-white shadow-lg  pulse default-transition':
            _vm.flavour.wetFood === 'Mixed'
        }},[_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.flavour.wetFood),expression:"flavour.wetFood"}],attrs:{"id":"wetFoodMixed","type":"radio","value":"Mixed"},domProps:{"checked":_vm._q(_vm.flavour.wetFood,"Mixed")},on:{"change":function($event){return _vm.$set(_vm.flavour, "wetFood", "Mixed")}}}),_c('label',{staticClass:"ml-2",attrs:{"for":"wetFoodMixed"}},[_vm._v("Mixed")])]),_c('div',[(_vm.flavour.wetFood === 'Mixed')?_c('font-awesome-icon',{staticClass:"text-purple-500 animated bounceIn default-transition",attrs:{"icon":['far', 'check']}}):_vm._e()],1)])]):_vm._e(),(_vm.selectedPlan.dryfood != 0)?_c('div',{staticClass:"w-full sm:w-2/3 md:w-1/2 text-sm text-left lg:p-4 pt-0 bg-purple-100 rounded mx-auto pb-4 border-purple-300"},[(_vm.selectedPlan.pouches != 0)?_c('div',{staticClass:"mb-3 text-center font-medium text-xs uppercase"},[_vm._v(" Dry Food ")]):_vm._e(),_c('div',{staticClass:"flex items-center p-2 px-3 rounded justify-between",class:{
          'bg-white shadow-lg  pulse default-transition':
            _vm.flavour.dryFood === 'Chicken'
        }},[_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.flavour.dryFood),expression:"flavour.dryFood"}],attrs:{"id":"dryFoodChicken","type":"radio","value":"Chicken"},domProps:{"checked":_vm._q(_vm.flavour.dryFood,"Chicken")},on:{"change":function($event){return _vm.$set(_vm.flavour, "dryFood", "Chicken")}}}),_c('label',{staticClass:"ml-2",attrs:{"for":"dryFoodChicken"}},[_vm._v("Chicken")])]),_c('div',[(_vm.flavour.dryFood === 'Chicken')?_c('font-awesome-icon',{staticClass:"text-purple-500 animated bounceIn default-transition",attrs:{"icon":['far', 'check']}}):_vm._e()],1)]),_c('div',{staticClass:"flex items-center p-2 px-3 rounded justify-between",class:{
          'bg-white shadow-lg  pulse default-transition':
            _vm.flavour.dryFood === 'Salmon'
        }},[_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.flavour.dryFood),expression:"flavour.dryFood"}],attrs:{"id":"dryFoodSalmon","type":"radio","value":"Salmon"},domProps:{"checked":_vm._q(_vm.flavour.dryFood,"Salmon")},on:{"change":function($event){return _vm.$set(_vm.flavour, "dryFood", "Salmon")}}}),_c('label',{staticClass:"ml-2",attrs:{"for":"dryFoodSalmon"}},[_vm._v("Salmon")])]),_c('div',[(_vm.flavour.dryFood === 'Salmon')?_c('font-awesome-icon',{staticClass:"text-purple-500 animated bounceIn default-transition",attrs:{"icon":['far', 'check']}}):_vm._e()],1)]),_c('div',{staticClass:"flex items-center p-2 px-3 rounded justify-between",class:{
          'bg-white shadow-lg  pulse default-transition':
            _vm.flavour.dryFood === 'Turkey'
        }},[_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.flavour.dryFood),expression:"flavour.dryFood"}],attrs:{"id":"dryFoodTurkey","type":"radio","value":"Turkey"},domProps:{"checked":_vm._q(_vm.flavour.dryFood,"Turkey")},on:{"change":function($event){return _vm.$set(_vm.flavour, "dryFood", "Turkey")}}}),_c('label',{staticClass:"ml-2",attrs:{"for":"dryFoodTurkey"}},[_vm._v("Turkey")])]),_c('div',[(_vm.flavour.dryFood === 'Turkey')?_c('font-awesome-icon',{staticClass:"text-purple-500 animated bounceIn default-transition",attrs:{"icon":['far', 'check']}}):_vm._e()],1)])]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"ml-2",attrs:{"for":"wetFoodChicken"}},[_vm._v("Chicken "),_c('span',{staticClass:"text-xs opacity-50"},[_vm._v("(incl. Chicken breast)")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"ml-2",attrs:{"for":"wetFoodTuna"}},[_vm._v("Tuna "),_c('span',{staticClass:"text-xs opacity-50"},[_vm._v("(incl. red & white meat)")])])
}]

export { render, staticRenderFns }