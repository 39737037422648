<template>
  <div class=" bg-purple-100 pb-2">
    <div class="text-md font-merri  text-center mb-4 pt-4">
      Choose your flavours:
    </div>
    <div class="flex flex-col lg:flex-row">
      <div
        v-if="selectedPlan.pouches != 0"
        class="w-full sm:w-2/3 md:w-1/2 text-sm text-left lg:p-4 pt-0   bg-purple-100 rounded mx-auto   pb-4  border-purple-300"
      >
        <div
          v-if="selectedPlan.dryfood != 0"
          class="mb-3 text-center font-medium text-xs  uppercase"
        >
          wet pouches
        </div>
        <div
          class="flex items-center p-2 px-3 rounded justify-between "
          :class="{
            'bg-white shadow-lg  pulse default-transition':
              flavour.wetFood === 'Chicken'
          }"
        >
          <div>
            <input
              id="wetFoodChicken"
              type="radio"
              value="Chicken"
              v-model="flavour.wetFood"
            />
            <label for="wetFoodChicken" class="ml-2"
              >Chicken
              <span class="text-xs opacity-50"
                >(incl. Chicken breast)</span
              ></label
            >
          </div>
          <div>
            <font-awesome-icon
              class="text-purple-500  animated bounceIn default-transition"
              v-if="flavour.wetFood === 'Chicken'"
              :icon="['far', 'check']"
            />
          </div>
        </div>
        <div
          class="flex items-center p-2 px-3 rounded justify-between"
          :class="{
            'bg-white shadow-lg  pulse default-transition':
              flavour.wetFood === 'Tuna'
          }"
        >
          <div>
            <input
              id="wetFoodTuna"
              type="radio"
              value="Tuna"
              v-model="flavour.wetFood"
            />
            <label for="wetFoodTuna" class="ml-2"
              >Tuna
              <span class="text-xs opacity-50"
                >(incl. red &amp; white meat)</span
              ></label
            >
          </div>
          <div>
            <font-awesome-icon
              class="text-purple-500  animated bounceIn default-transition"
              v-if="flavour.wetFood === 'Tuna'"
              :icon="['far', 'check']"
            />
          </div>
        </div>
        <div
          class="flex items-center p-2 px-3 rounded justify-between"
          :class="{
            'bg-white shadow-lg  pulse default-transition':
              flavour.wetFood === 'Mixed'
          }"
        >
          <div>
            <input
              id="wetFoodMixed"
              type="radio"
              value="Mixed"
              v-model="flavour.wetFood"
            />
            <label for="wetFoodMixed" class="ml-2">Mixed</label>
          </div>
          <div>
            <font-awesome-icon
              class="text-purple-500  animated bounceIn default-transition"
              v-if="flavour.wetFood === 'Mixed'"
              :icon="['far', 'check']"
            />
          </div>
        </div>
      </div>
      <div
        v-if="selectedPlan.dryfood != 0"
        class="w-full sm:w-2/3 md:w-1/2 text-sm text-left lg:p-4  pt-0   bg-purple-100 rounded mx-auto   pb-4  border-purple-300"
      >
        <div
          class="mb-3 text-center font-medium text-xs  uppercase"
          v-if="selectedPlan.pouches != 0"
        >
          Dry Food
        </div>
        <div
          class="flex items-center p-2 px-3 rounded justify-between"
          :class="{
            'bg-white shadow-lg  pulse default-transition':
              flavour.dryFood === 'Chicken'
          }"
        >
          <div>
            <input
              id="dryFoodChicken"
              type="radio"
              value="Chicken"
              v-model="flavour.dryFood"
            />
            <label for="dryFoodChicken" class="ml-2">Chicken</label>
          </div>
          <div>
            <font-awesome-icon
              class="text-purple-500  animated bounceIn default-transition"
              v-if="flavour.dryFood === 'Chicken'"
              :icon="['far', 'check']"
            />
          </div>
        </div>
        <div
          class="flex items-center p-2 px-3 rounded justify-between"
          :class="{
            'bg-white shadow-lg  pulse default-transition':
              flavour.dryFood === 'Salmon'
          }"
        >
          <div>
            <input
              id="dryFoodSalmon"
              type="radio"
              value="Salmon"
              v-model="flavour.dryFood"
            />
            <label for="dryFoodSalmon" class="ml-2">Salmon</label>
          </div>
          <div>
            <font-awesome-icon
              class="text-purple-500  animated bounceIn default-transition"
              v-if="flavour.dryFood === 'Salmon'"
              :icon="['far', 'check']"
            />
          </div>
        </div>
        <div
          class="flex items-center p-2 px-3 rounded justify-between"
          :class="{
            'bg-white shadow-lg  pulse default-transition':
              flavour.dryFood === 'Turkey'
          }"
        >
          <div>
            <input
              id="dryFoodTurkey"
              type="radio"
              value="Turkey"
              v-model="flavour.dryFood"
            />
            <label for="dryFoodTurkey" class="ml-2">Turkey</label>
          </div>
          <div>
            <font-awesome-icon
              class="text-purple-500  animated bounceIn default-transition"
              v-if="flavour.dryFood === 'Turkey'"
              :icon="['far', 'check']"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["dryfood", "wetfood", "plan"],
  data() {
    return {
      selectedPlan: this.plan,
      flavour: {
        wetFood: this.wetfood ? this.wetfood : "Chicken",
        dryFood: this.dryfood ? this.dryfood : "Chicken"
      }
    };
  },
  watch: {
    flavour: {
      deep: true,
      handler(value) {
        this.$emit("has-flavour", value);
      }
    }
  }
};
</script>

<style lang="css" scoped></style>
